import { mapValues, filter } from 'lodash';
import { useState, useEffect } from 'react';
import { isDefined, isOfType, keys } from '~/ts-utils';
import { getImageUrl, getVideoPosterUrl } from '~commons/components/review-form/get-media-url';
import { emailRegex } from '@wix/wix-js-validations';
import { InvalidFields } from './types';
import { Contact, ReviewContent, ReviewCreateError } from '~commons/types';
import { MediaState } from './media-input/types';
import { CommonsTranslationKey } from '~commons/locale-types';

type Field<T> = {
  value: T;
  updated: boolean;
  error?: CommonsTranslationKey;
};

type FormValidationError = {
  timestamp: number;
  invalidFields: InvalidFields;
};

type InitialContent = ReviewContent & { name?: string; email?: string };

type ReviewContentState = {
  title: string;
  body: string;
  rating: number;
  media: MediaState[];
};

type FormFields = {
  [K in keyof Contact]: Field<Contact[K]>;
} & {
  [K in keyof ReviewContentState]: Field<ReviewContentState[K]>;
};

export type FormStateType<T> = {
  shouldShowFieldErrors: boolean;
  submissionError?: ReviewCreateError<T>;
  validationError?: FormValidationError;
  fields: FormFields;
};

const toReviewContent = (content: ReviewContentState): ReviewContent => {
  return {
    title: content.title.trim() || undefined,
    body: content.body.trim() || undefined,
    rating: content.rating,
    // Backend takes only id and populates other information directly from media service, so we skip other fileds
    media: content.media
      .map((m) => {
        if (m.type !== 'READY') {
          return undefined;
        }
        return m.mediaType === 'image' ? { image: { id: m.id } } : { video: { id: m.id } };
      })
      .filter(isDefined),
  };
};

const toReviewContentState = (content: ReviewContent): ReviewContentState => {
  return {
    title: content.title ?? '',
    body: content.body ?? '',
    rating: content.rating ?? 0,
    media: content.media.map((m) => {
      if ('image' in m) {
        return {
          type: 'READY' as const,
          mediaType: 'image',
          id: m.image.id!,
          localId: m.image.id!,
          filename: m.image.filename || '',
          thumbnailSrc: getImageUrl(m.image, {
            maxWidth: 200,
            maxHeight: 200,
            type: 'fill',
          }),
        };
      } else {
        return {
          type: 'READY' as const,
          mediaType: 'video',
          id: m.video.id!,
          localId: m.video.id!,
          filename: m.video.resolutions?.[0].poster?.filename || '',
          thumbnailSrc: getVideoPosterUrl(m.video.resolutions![0].poster!, {
            maxWidth: 200,
            maxHeight: 200,
            type: 'fill',
          }),
        };
      }
    }),
  };
};

const toField = <T extends unknown>(f: T): Field<T> => ({
  value: f,
  updated: false,
});

const toValue = <T extends unknown>(f: Field<T>): T => f.value;

const unwrapFieldValues = (
  fields: FormFields,
): {
  [K in keyof FormFields]: FormFields extends Field<infer R> ? R : never;
} => {
  return mapValues(fields, toValue) as any;
};

const getInitialFormState = <T,>(
  content: InitialContent,
  submissionError?: ReviewCreateError<T>,
): FormStateType<T> => ({
  shouldShowFieldErrors: false,
  submissionError,
  fields: mapValues(
    {
      ...toReviewContentState(content),
      name: content.name ?? '',
      email: content.email ?? '',
    },
    toField,
  ) as any,
});

const required =
  (errKey: CommonsTranslationKey) =>
  (val?: any): CommonsTranslationKey | undefined =>
    val ? undefined : errKey;
const validEmail = (val: string): CommonsTranslationKey | undefined =>
  emailRegex.test(val) ? undefined : 'field-error.invalid-email';

const emailValidator = (val: string | undefined) => {
  return required('field-error.required.email')(val) ?? validEmail(val!);
};

const getValidators = ({
  isContactRequired,
  isTitleRequired,
  isBodyRequired,
  isMediaRequired,
}: {
  isContactRequired: boolean;
  isTitleRequired: boolean;
  isBodyRequired: boolean;
  isMediaRequired: boolean;
}): {
  [K in keyof FormFields]: (val: FormFields[K]['value']) => CommonsTranslationKey | undefined;
} => ({
  rating: required('field-error.required.rating'),
  body: isBodyRequired ? required('field-error.required.body') : () => undefined,
  title: isTitleRequired ? required('field-error.required.title') : () => undefined,
  media: (media) => {
    if (isMediaRequired && media.length === 0) {
      return required('field-error.required.media')();
    }
    return media.every(isOfType(['READY', 'ERROR']))
      ? undefined
      : 'field-error.media-still-uploading';
  },
  name: isContactRequired ? required('field-error.required.name') : () => undefined,
  email: isContactRequired ? emailValidator : () => undefined,
});

export const useReviewFormState = <T,>({
  initialContent,
  isContactRequired,
  isTitleRequired,
  isBodyRequired,
  isMediaRequired,
  submissionError,
}: {
  initialContent: InitialContent;
  isContactRequired: boolean;
  isTitleRequired: boolean;
  isBodyRequired: boolean;
  isMediaRequired: boolean;
  submissionError?: ReviewCreateError<T>;
}) => {
  const [state, setState] = useState<FormStateType<T>>(
    getInitialFormState(initialContent, submissionError),
  );

  useEffect(() => {
    setState((s) => ({ ...s, submissionError }));
  }, [submissionError]);

  return {
    onFieldChange: <K extends keyof FormFields>(
      contentKey: K,
      value: FormFields[K]['value'] | ((val: FormFields[K]['value']) => FormFields[K]['value']),
    ): void => {
      setState((prevState) => {
        const newValue =
          typeof value === 'function' ? value(prevState.fields[contentKey].value) : value;
        return {
          ...prevState,
          fields: {
            ...prevState.fields,
            [contentKey]: {
              value: newValue,
              updated: true,
              error: state.shouldShowFieldErrors
                ? getValidators({
                    isContactRequired,
                    isTitleRequired,
                    isBodyRequired,
                    isMediaRequired,
                  })[contentKey](newValue)
                : undefined,
            },
          },
        };
      });
    },
    reset: () => setState(getInitialFormState(initialContent)),
    isValid: () =>
      !keys(state.fields)
        .map((key) =>
          getValidators({ isContactRequired, isTitleRequired, isBodyRequired, isMediaRequired })[
            key
          ](
            // @ts-expect-error
            state.fields[key].value,
          ),
        )
        .some(isDefined),
    getContent: () => toReviewContent(unwrapFieldValues(state.fields)),
    getContact: () => {
      const { name, email } = unwrapFieldValues(state.fields);
      return { name, email };
    },
    validate: () => {
      let invalidFields: InvalidFields | undefined;
      setState((s) => {
        const fields = mapValues(s.fields, (f, key) => {
          return {
            ...f,
            error: getValidators({
              isContactRequired,
              isTitleRequired,
              isBodyRequired,
              isMediaRequired,
            })[key as keyof FormFields](
              // @ts-expect-error
              f.value,
            ),
          };
        }) as any as FormFields;
        invalidFields = filter(keys(fields), (key) => !!fields[key].error);

        return {
          ...s,
          shouldShowFieldErrors: true,
          validationError: {
            timestamp: Date.now(),
            invalidFields,
          },
          fields,
        };
      });
      return invalidFields;
    },
    getUpdatedFields: () => keys(state.fields).filter((key) => state.fields[key].updated),
    submissionError: state.submissionError,
    validationError: state.validationError,
    clearSubmissionError: () => {
      setState((s) => ({ ...s, submissionError: undefined }));
    },
    forceErrorState: () => {
      const validators = getValidators({
        isContactRequired: true,
        isTitleRequired: true,
        isBodyRequired: true,
        isMediaRequired: false,
      });
      setState((s) => {
        const fields = mapValues(s.fields, (f, key: keyof FormFields) => ({
          ...f,
          error: validators[key](
            // @ts-expect-error
            key === 'media' ? [] : undefined,
          ),
        })) as any as FormFields;

        return {
          ...s,
          shouldShowFieldErrors: true,
          validationError: {
            timestamp: Date.now(),
            invalidFields: filter(keys(fields), (key) => !!fields[key].error),
          },
          fields,
        };
      });
    },
    fields: state.fields,
  };
};
