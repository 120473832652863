import React from 'react';
import { uniqueId } from 'lodash';
import { isDefined, isOfType, unreachable } from '~/ts-utils';
import { FileUpload } from './file-upload';
import { getImageUrl } from '../get-media-url';
import { MediaType } from './upload-types';
import {
  MediaState,
  MediaStatePending,
  MediaStateReady,
  AllowedMediaTypes,
  UploadMediaFn,
} from './types';
import { classes, style } from './media-input.st.css';
import { MediaThumbnail } from './media-thumbnail';
import { FormErrorNotification } from '../form-error-notification/form-error-notification';
import { useHighlightMedia } from './use-highlight-media';
import { MEDIA_SECTION_ERROR, MEDIA_INPUT_PENDING, MEDIA_INPUT_THUMBNAIL } from './data-hooks';
import { AddItem } from './add-item';
import ErrorSmall from 'wix-ui-icons-common/on-stage/ErrorSmall';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useTranslate } from '~commons/hooks/use-translate';
import { CommonsTranslationKey } from '~commons/locale-types';

export const MediaInput: React.FC<{
  onChange: (updateFn: (media: MediaState[]) => MediaState[]) => void;
  onMediaDoneUploading?: (event: { imageCount: number; videoCount: number }) => void;
  onMediaRemoved?: () => void;
  uploadMediaFileFn: UploadMediaFn;
  media: MediaState[];
  label: string;
  className?: string;
  disabled?: boolean;
  maxLength: number;
  id?: string;
  errorMessage?: string;
  error?: boolean;
  allowedMedia?: AllowedMediaTypes;
  dataHook?: string;
  'aria-label'?: string;
}> = ({
  onChange,
  onMediaDoneUploading,
  onMediaRemoved,
  media,
  label,
  className,
  disabled,
  maxLength,
  id,
  errorMessage,
  allowedMedia = 'all',
  error,
  dataHook,
  uploadMediaFileFn,
  'aria-label': ariaLabel = label,
}) => {
  const t = useTranslate<CommonsTranslationKey>();
  const { isMobile } = useEnvironment();
  const inputId = React.useMemo(() => uniqueId('file-input-id'), []);
  const visibleMediaState = media.filter(isOfType(['READY', 'PENDING']));
  const errorMediaStates = media.filter(isOfType(['ERROR']));
  const { errorSectionId } = useHighlightMedia(errorMediaStates);
  const acceptedMediaTypes = [
    allowedMedia === 'all' || allowedMedia === 'image' ? 'image/*' : undefined,
    allowedMedia === 'all' || allowedMedia === 'video' ? 'video/*' : undefined,
  ];

  const onUploadDone = (files: File[]) => {
    const uploadedMedia: ('image' | 'video')[] = [
      ...visibleMediaState.map((m) => m.mediaType),
      ...files.reduce<('image' | 'video')[]>((acc, f) => {
        if (f.type.startsWith('image/')) {
          return [...acc, 'image'];
        } else if (f.type.startsWith('video/')) {
          return [...acc, 'video'];
        }
        return acc;
      }, []),
    ];
    onMediaDoneUploading?.({
      imageCount: uploadedMedia.filter((m) => m === 'image').length,
      videoCount: uploadedMedia.filter((m) => m === 'video').length,
    });
  };

  return (
    <div
      className={style(classes.root, { mobile: isMobile }, className)}
      id={id}
      role="group"
      data-hook={dataHook}
    >
      <label htmlFor={inputId} className={classes.label}>
        {label}
      </label>
      <div className={classes.mediaRow}>
        <FileUpload
          id={inputId}
          multiple
          accept={acceptedMediaTypes.filter(isDefined).join(',')}
          onChange={onFileUpload(
            onChange,
            onUploadDone,
            uploadMediaFileFn,
            maxLength - visibleMediaState.length,
          )}
          className={classes.fileInput}
        >
          {({ openFileUploadDialog }) => (
            <AddItem
              aria-label={ariaLabel}
              error={!!error}
              disabled={disabled}
              onClick={openFileUploadDialog}
            />
          )}
        </FileUpload>
        <ul
          aria-live="assertive"
          aria-label={t('review.media-aria-label')}
          className={classes.mediaList}
        >
          {visibleMediaState.map((mediaState) => (
            <li className={classes.mediaListItem}>
              <MediaItemStateSwitch
                key={mediaState.localId}
                mediaState={mediaState}
                onRemove={() => {
                  onChange((_media) => {
                    return _media.filter((m) => m.localId !== mediaState.localId);
                  });
                  onMediaRemoved?.();
                }}
                isMobile={isMobile}
              />
            </li>
          ))}
        </ul>
      </div>
      {errorMessage && (
        <div className={classes.errorMessage}>
          <ErrorSmall aria-hidden={true} className={classes.errorIcon} />
          {errorMessage}
        </div>
      )}
      <div className={classes.errorSection} id={errorSectionId} aria-live="assertive">
        {errorMediaStates.map((mediaState) => (
          <FormErrorNotification
            key={mediaState.localId}
            className={classes.errorItem}
            text={t('media-error.template', {
              filename: mediaState.filename,
              message: t('media-error.generic'),
            })}
            onClose={() => onChange((ms) => ms.filter((m) => m.localId !== mediaState.localId))}
            dataHook={MEDIA_SECTION_ERROR}
          />
        ))}
      </div>
    </div>
  );
};

const onFileUpload =
  (
    onChange: (updateFn: (media: MediaState[]) => MediaState[]) => void,
    onUploadDone: (files: File[]) => void,
    uploadMediaFile: UploadMediaFn,
    allowedCount: number,
  ) =>
  (fileListAll: FileList) => {
    const fileArray: File[] = Array.prototype.slice.call(fileListAll, 0, allowedCount);
    if (!fileArray.length) {
      return;
    }
    const pendingMediaStates: MediaState[] = fileArray.map((f) =>
      f.type.startsWith('image/')
        ? {
            type: 'PENDING',
            mediaType: 'image',
            localId: uniqueId(),
            thumbnailSrc: URL.createObjectURL(f),
            filename: f.name,
          }
        : {
            type: 'PENDING',
            mediaType: 'video',
            localId: uniqueId(),
            filename: f.name,
            thumbnailSrc: URL.createObjectURL(f),
          },
    );
    onChange((media) => [...media, ...pendingMediaStates]);

    const promises = fileArray.map((file: File, index) => {
      const localId = pendingMediaStates[index].localId;
      const fileType: MediaType | undefined = file.type.startsWith('image/')
        ? 'picture'
        : file.type.startsWith('video/')
        ? 'video'
        : undefined;

      if (!fileType) {
        console.error(`Unsupported file type ${file.type}`);
        return Promise.reject();
      }

      const promise = uploadMediaFile({
        file,
        type: fileType,
      });
      promise
        .then((r) => {
          const pendingState = pendingMediaStates.find((m) => m.localId === localId);
          if (!pendingState) {
            return;
          }
          if (pendingState.mediaType === 'image') {
            URL.revokeObjectURL(pendingState.thumbnailSrc);
          }
          onChange((media) =>
            media.map((m) =>
              m.localId === localId
                ? {
                    type: 'READY',
                    mediaType: r.media_type === 'picture' ? 'image' : 'video',
                    localId,
                    id: r.file_name,
                    filename: r.original_file_name,
                    thumbnailSrc:
                      r.media_type === 'picture'
                        ? getImageUrl(
                            {
                              id: r.file_name,
                              width: r.width,
                              height: r.height,
                            },
                            {
                              maxWidth: 200,
                              maxHeight: 200,
                              type: 'fill',
                            },
                          )
                        : pendingState.thumbnailSrc,
                  }
                : m,
            ),
          );
        })
        .catch((error) => {
          const pendingState = pendingMediaStates.find((m) => m.localId === localId);
          if (!pendingState) {
            return;
          }
          onChange((media) =>
            media.map((m) =>
              m.localId === localId
                ? {
                    type: 'ERROR',
                    message: `Network request failed with ${error.status}`,
                    mediaType: pendingState.mediaType,
                    localId: pendingState.localId,
                    thumbnailSrc: pendingState.thumbnailSrc,
                    filename: pendingState.filename,
                  }
                : m,
            ),
          );
        });
      return promise;
    });
    Promise.all(promises).then(() => onUploadDone(fileArray));
  };

const MediaItemStateSwitch: React.FC<{
  mediaState: MediaStateReady | MediaStatePending;
  onRemove: () => void;
  isMobile?: boolean;
}> = ({ mediaState, onRemove, isMobile }) => {
  const dimensions = isMobile ? { height: 80, width: 80 } : { height: 100, width: 100 };
  switch (mediaState.type) {
    case 'READY': {
      return (
        <MediaThumbnail
          dataHook={MEDIA_INPUT_THUMBNAIL}
          onRemove={onRemove}
          isPending={false}
          src={mediaState.thumbnailSrc}
          filename={mediaState.filename}
          type={mediaState.mediaType}
          {...dimensions}
        />
      );
    }
    case 'PENDING': {
      return (
        <MediaThumbnail
          dataHook={MEDIA_INPUT_PENDING}
          isPending={true}
          onRemove={onRemove}
          src={mediaState.thumbnailSrc}
          filename={mediaState.filename}
          type={mediaState.mediaType}
          {...dimensions}
        />
      );
    }
    default:
      throw unreachable(mediaState);
  }
};
