import React from 'react';
import { ReviewForm } from '~commons/components/review-form/review-form';
import { useApi } from '../api-provider/use-api';
import { useFormSettings } from '~commons/hooks/use-form-settings';
import { ReviewContent } from '~commons/types';

export const DEFAULT_REVIEW_CONTENT: ReviewContent = {
  title: '',
  body: '',
  rating: 0,
  media: [],
};

export const CollectionForm: React.FC = () => {
  const { isFormPending, createReview, configuration } = useApi((state, actions) => ({
    isFormPending: state.formStatus === 'PENDING',
    createReview: (param: any) => {}, // TODO: implement createReview action
    configuration: {
      id: 'ccc528f1-4f8b-40ac-8bbf-50557c69a48a',
      revision: '1',
      ownerDisplayName: 'Store Owner',
      body: {
        enabled: false,
        required: false,
      },
      title: {
        enabled: false,
        required: false,
      },
      media: {
        enabled: false,
        required: false,
      },
      customFieldNames: {},
      moderationRules: {
        wordsFilter: {
          words: [],
          active: false,
        },
        ratingValues: {
          ratingValues: [],
          active: false,
        },
        mediaFilter: {
          active: false,
        },
      },
      notificationSettings: {
        reviewReminder: {
          active: false,
          afterSeconds: 0,
        },
        newReviewBMNotification: {
          active: false,
        },
      },
      helpfulVotes: {
        active: false,
        helpfulVotesOnly: false,
      },
    },
  }));
  const formSettings = useFormSettings(configuration);
  return (
    <ReviewForm
      id="review-collection-form"
      isEdit={false}
      isPending={isFormPending}
      onCancel={(content, reset, isEmpty) => {
        return { type: 'SILENT_CANCEL', cleanup: () => {} };
      }}
      requireContact
      onSubmit={({ content, contact }) => {
        createReview({
          content,
          // TODO: Create as member when logged in
          createAs: {
            type: 'VISITOR',
            contact: { name: contact.name, email: contact.email },
          },
        });
      }}
      formSettings={formSettings}
      initialContent={DEFAULT_REVIEW_CONTENT}
      onLoginClick={() => {}}
      onMediaUpload={(() => {}) as any}
    />
  );
};
